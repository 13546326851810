@import "variables";
@import "bootstrap";
@import "font-awesome/scss/font-awesome";

@import "typography";
@import "forms";

@import "header";
@import "sidebar";
@import "main";
@import "footer";

@import "components/socials";
@import "components/gallery";
@import "components/testimonials";
@import "components/thumbnails";
@import "components/owl-carousel";
@import "components/audio-player";

@import "pages/contact";
@import "pages/about";

html {
    height: 100%;
    box-sizing: border-box;
}

body {
    background: #fff;
    min-height: 100%;
    position: relative;
    padding-bottom: 270px;
}
