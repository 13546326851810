#main {
    margin-top: 15px;
    font-weight: 300;
    font-size: 17px;
    color: #54657E;

    &.subpage-content {
        margin-top: 0;
        border-top: 12px solid $brand-primary;
        padding-top: 25px;
    }

    section.hero {
        position: relative;
        margin-top: -15px;

        img {
            width: 100%;
        }

        .content {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            transform: translateY(-50%);
            color: #fff;
            text-align: center;

            h1 {
                font-family: 'Roboto Condensed';
                color: #fff;
                margin-top: 0;
                margin-bottom: 0;
                font-size: 22px;
                line-height: $line-height-base;

                @media (min-width: $screen-sm-min) {
                    font-size: 65px;
                    line-height: 70px;
                    margin-bottom: 30px;
                }
            }

            p {
                font-weight: 300;
                font-size: 14px;
                line-height: $line-height-base;
                color: #fff;

                @media (min-width: $screen-sm-min) {
                    max-width: 650px;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            .btn {
                border: 1px solid #fff;
                border-radius: 27px;
                background: rgba(#fff, .0);
                color: #fff;
                font-weight: 300;
                padding: 4px 30px;
                font-size: 14px;
                transition: all .25s ease-out;

                @media (min-width: $screen-sm-min) {
                    margin-top: 30px;
                    font-size: 15px;
                    padding: 10px 50px;
                }

                &:hover {
                    background: rgba(#fff, .1);
                }
            }
        }
    }

    section#featured-categories {
        margin-top: 15px;
    }
}

.gallery + h1 {
    margin-top: 15px;
}

h1 + .gallery {
    margin-top: -5px;
    margin-bottom: 25px;
}
