.socials {
    > ul {
        display: inline-block;
        list-style: none;
        padding: 0;
        @include clearfix;

        > li {
            display: block;
            float: left;

            + li {
                margin-left: 12px;
            }

            &.twitter > a > .fa {
                font-size: 20px;
            }

            &.facebook > a > .fa {
                font-size: 17px;
            }

            &.instagram > a > .fa {
                font-size: 18px;
            }

            > a {
                display: inline-block;
                font-size: 15px;
                color: #fff;
                background: #ADB9CA;
                width: 32px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                border-radius: 50%;
                transition: background .25s ease-out;

                &:hover {
                    background: darken(#ADB9CA, 20%);
                }

                > .fa {
                    line-height: 32px;
                }
            }
        }
    }
}
