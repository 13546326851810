body.page__about {
    h1 {
        margin-bottom: 12px;
        line-height: 70px;
    }

    #testimonials {
        margin-top: 15px;
    }

    .team-members {
        margin: 30px 0;

        .team-member {
            padding: 0;
            display: flex;
            margin-top: 15px;
            flex-direction: column;

            @media (min-width: 480px) {
                flex-direction: row;
                height: 200px;
            }

            @media (min-width: $screen-sm-min) {
                margin-top: 0;
            }

            .team-member-picture {
                display: block;
                width: 100%;
                align-self: center;

                @media (min-width: 480px) {
                    width: auto;
                    flex: 0 0 200px;
                    height: 200px;
                    @include border-left-radius(5px);
                }

                @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                    flex: 0 0 120px;
                    height: 120px;
                }
            }

            .details {
                padding: 30px 0;
                align-self: center;

                @media (min-width: 480px) {
                    padding: 0 30px;
                }

                h3 {
                    font-size: 25px;
                    color: #3D4D65;
                    line-height: 35px;
                    margin: 0 0 10px;
                }

                p {
                    font-size: 15px;
                    color: #54657E;
                    line-height: 25px;
                }
            }
        }
    }
}
