.thumbnail {
    padding: 0;
    border: 0;
    background: #ECEFF4;
    margin-top: 25px;

    img {
        width: 100%;
    }

    .caption {
        text-align: center;

        h2 {
            font-family: 'Roboto Condensed';
            font-weight: 400 !important;
            font-size: 20px;
            color: #3D4D65;
            line-height: 35px;
            margin: 0;
        }

        p {
            font-weight: 300;
            font-size: 15px;
            color: #54657E;
            line-height: 25px;
            margin-bottom: 0;
        }

        .btn {
            $btn-color: #FF9948;

            background: $btn-color;
            border-radius: 5px;
            font-weight: 500;
            font-size: 15px;
            color: #FFFFFF;
            padding: 14px 40px;
            margin: 20px 0;
            text-transform: none;

            &:hover {
                background: lighten($btn-color, 5%);
            }
        }
    }
}
