#footer {
    background: $footer-bg;
    margin-top: 15px;
    color: $footer-color;
    text-align: center;
    padding-top: 44px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    nav {
        > ul {
            list-style: none;
            padding: 0;

            > li {
                display: inline-block;

                + li {
                    margin-left: 45px;
                }

                > a {
                    font-size: 15px;
                    color: $footer-nav-color;
                }
            }
        }
    }

    .socials {
        margin-top: 43px;
        margin-bottom: 31px;

        > ul {
            > li {
                > a {
                    color: $footer-bg;
                    background: rgba(#fff, .4);

                    &:hover {
                        background: rgba(#fff, .7);
                    }
                }
            }
        }
    }

    .copyright {
        border-top: 1px solid #8493A8;
        font-weight: 300;
        font-size: 13px;
        color: #8493A8;
        padding: 16px;
    }
}