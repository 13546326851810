$nav-link-hover-bg: transparent;

$brand-primary: #3D4D65;
$text-color: #707070;

$btn-success-bg: #9DBF16;

$footer-bg: $brand-primary;
$footer-color: #fff;
$footer-nav-color: $footer-color;

$font-family-custom: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base: $font-family-custom;
