.audio-player {
    position: relative;
    display: flex;
    background: #eceff4;
    border-radius: 5px;
    opacity: .4;

    .artwork {
        position: relative;
        display: block;
        flex: 0 0 120px;
        width: 120px;

        @media (min-width: $screen-sm-min) {
            flex: 0 0 140px;
            width: 140px;
        }

        @media (min-width: $screen-md-min) {
            flex: 0 0 170px;
            width: 170px;
        }

        img {
            width: 100%;
        }

        .play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -30px;
            margin-left: -30px;
            width: 60px;
            height: 60px;
            opacity: 0;
            transition: opacity .25s ease-in-out;

            .btn {
                display: block;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background: transparent;
                background-image: linear-gradient(-90deg, rgba(#A234D5, .7) 0%, rgba(#D74059, .7) 100%);
                border: 2px solid #fff;
                outline: 0;

                &:after {
                    content: "\f04b";
                    font-family: FontAwesome;
                    font-size: 24px;
                    color: #fff;
                    margin-left: -2px;
                }
            }
        }

        &:hover {
            .play-button {
                opacity: 1;
            }
        }
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px;

        @media (min-width: $screen-sm-min) {
            padding: 30px;
        }

        .song-name {
            font-weight: 300;
            font-size: 15px;
            color: #54657E;
        }

        .song-tracker {
            display: flex;
            flex-direction: row;
            font-weight: 400;
            font-size: 12px;
            color: #8493A8;

            .current-time {
                padding-right: 10px;
            }

            .time-range {
                flex: 1;
                align-self: center;

                .time-range-base {
                    background: #FFFFFF;
                    border: 1px solid #CAD3DF;
                    border-radius: 2.5px;
                    height: 4px;

                    .time-range-current {
                        position: relative;
                        height: 5px;
                        margin: -1px 0 0 -1px;
                        background-image: linear-gradient(270deg, #CA3058 0%, #FF9A48 100%);
                        border-radius: 2.5px;

                        &:after {
                            position: absolute;
                            content: "";
                            right: -7px;
                            top: -4px;
                            height: 13px;
                            width: 13px;
                            border-radius: 50%;
                            background: #CA3058;
                            transform-origin: 50% 50%;
                            transform: scale(0, 0);
                            transition: all .1s ease-out;
                            // box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.4);
                        }
                    }
                }

                &:hover,
                &.active {
                    .time-range-current {
                        &:after {
                            transform: scale(1, 1)
                        }
                    }
                }
            }

            .remaining-time {
                padding-left: 10px;
            }
        }

        .controls {
            text-align: center;

            a {
                color: #8493A8;
                text-decoration: none;

                + a {
                    margin-left: 16px;
                }
            }

            .toggle-play {
                .fa {
                    width: 14px;
                }
            }

            .prev,
            .next {
                display: inline-block;
                transform: scale(1.5, 1);

                + .prev,
                + .next {
                    margin-left: 24px;
                }
            }
        }
    }

    &.playable {
        opacity: 1;
    }
}