body.page__contact {
    h1 {
        margin-bottom: 12px;
        line-height: 70px;
    }

    h2 {
        margin-bottom: 17px;
        font-size: 35px;
    }

    .contact-info {
        p {
            font-size: 15px;
            line-height: 25px;
        }
    }

    .tel {
        text-decoration: none;
        font-size: 18px;

        .fa {
            color: $text-color;
            width: 20px;
            text-align: right;
            padding-right: 5px;
        }
    }

    .map-container {
        margin: 20px 0;
    }

    .email-address {
        font-weight: 400;
        margin: 15px 0;

        .fa {
            margin-right: 5px;
        }

        a {
            color: #0082D5;
        }
    }
}
