.owl-carousel {
    .owl-nav {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        height: 0;
        margin-top: -24px;

        > div {
            position: absolute;
            width: 48px;
            height: 48px;
            line-height: 46px;
            text-align: center;
            border-radius: 50%;
            background: rgba(55,67,85, .7);
            border: 2px solid #fff;
            font-size: 22px;
        }

        .owl-prev {
            left: 11px;

            &:before {
                content: "\f104";
                font-family: FontAwesome;
                color: #fff;
                line-height: 1;
                margin-left: -2px;
            }
        }

        .owl-next {
            right: 11px;

            &:before {
                content: "\f105";
                font-family: FontAwesome;
                color: #fff;
                line-height: 1;
                margin-left: 2px;
            }
        }
    }
}