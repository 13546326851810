form {
    .row {
        margin: 0 -7.5px;

        > * {
            padding: 0 7.5px;
        }
    }
}

.form-control {
    background: #ECEFF4;
    border: 1px solid #CAD3DF;
    border-radius: 5px;
    box-shadow: none;
    height: 45px;
    padding: 10px 15px;
    font-size: 15px;
}

.form-group .help-block {
    font-size: 14px;
}

.btn {
    padding: 12px 22px;
    font-size: 15px;
    border: 0;
    text-transform: uppercase;
}
