#sidebar {
    .panel {
        border: 0;
        box-shadow: none;
        border-radius: 5px;
        overflow: hidden;

        .panel-heading {
            background: $brand-primary;
            padding: 25px 30px;
            margin: 0;
            border-radius: 0;
            font-weight: 200;
            position: relative;
            z-index: 9;
            border: 0;
            font-family: 'Roboto Condensed';
            font-size: 20px;
            color: #ECEFF4;
        }

        .list-group {
            background: #ECEFF4;

            .list-group-item {
                padding: 15px 65px 15px 30px;
                color: #54657E;
                font-size: 15px;
                font-weight: 300;
                overflow: hidden;
                text-overflow: ellipsis;
                border: 0;
                margin-bottom: 0;
                border-radius: 0;
                position: relative;
                z-index: 1;
                background: none;

                &:after {
                    font-family: FontAwesome;
                    content: "\f105";
                    position: absolute;
                    top: 14px;
                    right: 30px;
                    font-size: 18px;
                }

                &:hover {
                    background: #e0e4ea;
                }

                &.active {
                    background: #e0e4ea;
                }
            }
        }
    }
}
