@import "owl.carousel.css";

.gallery {
    .gallery-item {
        video {
            width: 100%;
            margin: 0 auto;
            max-height: 100%;
        }
    }
}

.owl-carousel .owl-item {
    text-align: center;

    img {
        width: auto;
        display: inline;
    }
}