#testimonials {
    background: #f5f5f5;

    ul {
        list-style: none;
        padding: 0;

        li {
            padding: 30px 0;
            text-align: center;

            @media (min-width: $screen-sm-min) {
                padding-left: 7%;
                padding-right: 7%;
            }

            img {
                width: auto;
                display: inline-block;
                margin-bottom: 30px;
            }

            p {
                font-weight: 300;
                font-size: 17px;
                color: #374355;
                line-height: 30px;
                position: relative;

                &:before,
                &:after {
                    position: absolute;
                    font-size: 60px;
                    color: rgba(0,0,0,0.15);
                    line-height: 0;
                }

                &:before {
                    content: "\201c";
                    top: 0;
                    left: 0;
                }

                &:after {
                    content: "\201d";
                    bottom: 0;
                    right: 0;
                }
            }
        }
    }
}
