ol {
    padding: 0;
    list-style: none;
    counter-reset: list-counter;
    margin: 25px 0;

    li {
        counter-increment: list-counter;
        padding-left: 10px;

	+ li {
	    margin-top: 6px;
	}

        &:before {
            content: counter(list-counter) ".";
            margin-right: 10px;
            font-weight: 400;
        }
    }
}

h1, h2, h3, h4, h5, h6 {
    margin: 30px 0;
    font-weight: 400;
    color: #3D4D65;
    font-family: 'Roboto Condensed';
}

h1 {
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 65px;
    line-height: 70px;
}

p {
    font-size: 17px;
    line-height: 30px;
}
