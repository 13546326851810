$logo-width: 200px;
$logo-height: 62px;

#header {
    background: #fff;
    padding: 10px 0;

    .navbar-brand {
        text-indent: -9999px;
        background: url(/assets/images/logo.png) no-repeat center center;
        background-size: $logo-width $logo-height;
        width: $logo-width;
        height: $logo-height;
    }

    #navigation {
        margin-bottom: 0;

        &.open {
            .navbar-toggle {
                .icon-bar {
                    &.first { transform: rotate(-45deg) translateY(-100%) }
                    &.mid { opacity: 0 }
                    &.last { transform: rotate(45deg) translate(-5%) }
                }
            }
        }

        .navbar-toggle {
            margin-right: 0;
            margin-top: 14px;

            .icon-bar {
                border: 1px solid #ddd;
                transition: all .25s ease-out;

                &.first { transform-origin: 100% 50% }
                &.last { transform-origin: 80% 100% }
            }
        }

        .navbar-collapse {
            @media (min-width: $screen-sm-min) {
                padding-right: 0;
            }
        }

        ul {
            > li {
                + li {
                    @media (min-width: $screen-sm-min) {
                        margin-left: 30px;
                    }

                    @media (min-width: $screen-md-min) {
                        margin-left: 55px;
                    }
                }

                > a {
                    padding: 0;
                    height: $logo-height;
                    line-height: $logo-height;
                    color: #54657E;
                    transition: all .3s ease-out;
                    font-weight: 300;
                    font-size: 15px;

                    &:hover {
                        background: none;
                        color: #c57d65;
                    }
                }
            }
        }
    }
}